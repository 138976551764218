import gcIconStyles from '@gathercontent/icons/dist/icons.css';
import gcUiStyles from '@gathercontent/ui/dist/index.css';
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import { Outlet } from '@remix-run/react';
import { withSentry } from '@sentry/remix';
import { parse } from 'cookie';
import { useEffect } from 'react';
import { typedjson as json } from 'remix-typedjson';

import { getAccount } from '~/data/account/api/getAccount';
import { getApiCsrfToken } from '~/data/auth/api/getApiCsrfToken';
import { verifyUser } from '~/data/auth/api/verifyUser';
import { getBannerCookies } from '~/data/banners/app/getBannerCookies';
import { getClientEnvs } from '~/data/env/app/getClientEnvs';
import { useEnv } from '~/data/env/hooks/useEnv';
import { getUser } from '~/data/user/api/getUser';
import { useUser } from '~/data/user/hooks/useUser';

import { getSrcFromCDN } from '~/helpers/assets/getSrcFromCDN';
import { useSegmentPageViewTracking } from '~/helpers/hooks/segment/useSegmentPageViewTracking';
import { useSentry } from '~/helpers/hooks/useSentry';
import { createUserHash } from '~/helpers/intercom/createUserHash.server';

import { ModalProvider } from '~/components/.shared/modal/ModalProvider';
import { Document } from '~/components/root/Document';
import { ErrorBoundary as RootErrorBoundary } from '~/components/root/ErrorBoundary';
import { LiveReload } from '~/components/root/LiveReload';
import type { ScriptsFunction } from '~/components/root/scripts/ExternalScripts.client';

import twStyles from '../app/styles/tailwind.css';
import { ContactSupportModal } from './components/contact-support/modal/ContactSupportModal';
import { ShareFeedbackModal } from './components/share-feedback/modal/ShareFeedbackModal';
import { useFullStory } from './helpers/hooks/useFullStory';

export async function loader(args: LoaderFunctionArgs) {
  const consent = parse(args.request.headers.get('Cookie') || '').CookieConsent;

  const [tokenResult, verify, account, user, bannerCookies] = await Promise.all([
  getApiCsrfToken(args),
  verifyUser(args),
  getAccount(args),
  getUser(args),
  getBannerCookies(args.request)]
  );

  const loginLogo = verify.data ? verify.data.data?.attributes.loginLogo : '';
  const userHash = user.data ? createUserHash(user.data.data.id) : '';

  return json(
    {
      user,
      userHash,
      account,
      consent,
      loginLogo,
      verify,
      env: getClientEnvs(),
      csrf: tokenResult.data?.token,
      bannerCookies: bannerCookies
    },
    { headers: tokenResult.data?.headers }
  );
}

function App() {
  const { setupFullStory } = useFullStory();

  useSentry();
  useSegmentPageViewTracking();

  useEffect(setupFullStory, []);

  return (
    <Document>
      <ModalProvider>
        <ContactSupportModal />
        <ShareFeedbackModal />
        <Outlet />
      </ModalProvider>
      <LiveReload />
    </Document>);

}

export default withSentry(App);

export const links: LinksFunction = () => {
  return [
  {
    rel: 'stylesheet',
    href: 'https://d2g1g1dn8d5j5w.cloudfront.net/v0.0.25/ui/font.css'
  },
  { rel: 'stylesheet', href: gcUiStyles },
  { rel: 'stylesheet', href: gcIconStyles },
  { rel: 'stylesheet', href: twStyles },
  { rel: 'apple-touch-icon', sizes: '180x180', href: 'https://www.bynder.com/apple-touch-icon.png' },
  { rel: 'icon', type: 'image/png', sizes: '32x32', href: 'https://www.bynder.com/favicon-32x32.png' },
  { rel: 'icon', type: 'image/png', sizes: '16x16', href: 'https://www.bynder.com/favicon-16x16.png' },
  { rel: 'mask-icon', href: 'https://www.bynder.com/safari-pinned-tab.svg', color: '#5bbad5' }];

};

export const ErrorBoundary = RootErrorBoundary;

const scripts: ScriptsFunction = () => {
  const user = useUser();
  const env = useEnv();

  return [
  // Manually adding the segment analytics script here because the segment.js was causing hydration issues
  // It was modifying the client HTML that clashed with the server-rendered HTML
  {
    id: 'appcues',
    src: `https://cues.gathercontent.com/43992.js`,
    dataCookieConsent: !user ? 'statistics' : null,
    location: 'head'
  },
  {
    id: 'appcues-load',
    src: getSrcFromCDN('/scripts/appcues.js'),
    dataCookieConsent: !user ? 'statistics' : null,
    location: 'body'
  },
  {
    id: 'segment-analytics',
    src: env?.SEGMENT_KEY ? `https://cdn.segment.com/analytics.js/v1/${env.SEGMENT_KEY}/analytics.min.js` : '',
    dataCookieConsent: !user ? 'statistics' : null,
    location: 'head'
  },
  {
    id: 'segment',
    src: getSrcFromCDN('/scripts/segment.js'),
    dataCookieConsent: !user ? 'statistics' : null,
    location: 'body'
  }];

};

export const handle = { scripts };